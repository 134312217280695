import { Link } from "gatsby";
import * as React from "react";
import Layout from "../components/layout/layout";

// markup
const IndexPage = () => {
  return (
    <Layout title="Lovely Ladyverse - Media">
      <h1 class="flex-pageheading">Media</h1>
      <p class="flex-textbox">You can find all of the current media set in The Lovely Ladyverse...</p>
      <p class="flex-textbox"><a className="header-link" href="https://itch.io/c/449424/the-lovely-ladyverse">Here!</a></p>
    </Layout>
  );
};

export default IndexPage;
